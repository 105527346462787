<template>
    <div class="col-12 d-middle">
        <div class="row mx-0">
            <div class="col col-image text-center">
                <img src="/img/auth/postulacion_denegada.svg" style="max-width:100%;height:400px;" class="obj-cover responsive-image" />
            </div>
            <div class="col text-center">
                <p class="text-leeche f-30 f-600">
                    Ha ocurrido un error durante tu registro
                </p>
                <p class="f-30 text-muted2">
                    Llena de nuevo tu inscripción para ingresar y conocer una nueva experiencia de compra
                </p>
                <div class="row mx-0 justify-center mt-5">
                    <div class="col-5">
                        <router-link :to="{name: 'login.cliente'}">
                            <div class="bg-leeche br-12 text-center text-white py-2 my-2 cr-pointer">
                                Regresar
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{ }
    },
    methods: {}
}
</script>

<style lang="scss" scoped>
a{text-decoration: none;}
.br-12{ border-radius: 12px !important; }
@media (max-width: 1000px) {
    .col-image{
        .responsive-image{
            height: auto !important;
        }
    }
}
</style>
